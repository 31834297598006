export const ContactTypeValues = ["SELL", "BUY", "RENT", "MANAGE", "ASK_A_QUESTION"] as const
export const ContactPortalValue = [
  "BDS-FRA",
  "IMN-DEU",
  "IWB-BEL",
  "IWT-DEU",
  "IWT-AUT",
  "LCI-FRA",
  "LIM-FRA",
  "LIC-FRA",
  "LIN-FRA",
  "LUX-FRA",
  "MAG-FRA",
  "SLG-FRA",
  "BCM-FRA",
  "SLC-FRA",
  "SLN-FRA",
] as const

export const ContactLanguageValue = ["FR", "DE", "EN", "NL"] as const

export type ContactType = (typeof ContactTypeValues)[number]
export type ContactPortalType = (typeof ContactPortalValue)[number]
export type ContactLanguageType = (typeof ContactLanguageValue)[number]

export interface Contact {
  portal: ContactPortalType
  partnerId: string
  project: ContactType
  contactRequest: {
    message: string
    personContact: {
      email: string
      lastName: string
      firstName: string
      phone?: string
      language?: ContactLanguageType
    }
  }
}

export const isContact = (obj: any): obj is Contact => {
  return obj.portal && obj.partnerId && obj.project && obj.contactRequest
}
